<template>
  <div class="container">
    <div class="column is-half">
      <h2 class="subtitle has-text-light">
        Autoregistratie
      </h2>

      <h1 class="title has-text-light">
        Wat is je kenteken?
      </h1>
      <div class="box">
        <form
          @submit.prevent="
            updateregistration({
              step: 'licenseplate',
              registrationdata: {
                licenseplate: cardata.kenteken
                  .toUpperCase()
                  .replace(/[-/_]/g, ''),
              },
            })
          "
        >
          <div class="form-control">
            <div class="car-license">
              <div
                class="car-license__country-code"
              >
                <svg
                  class="svg"
                  viewBox="0 0 300 300"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="20"
                  height="20"
                  aria-labelledby="euSymbol"
                  role="img"
                >
                  <title id="euSymbol">EU symbol</title>
                  <g
                    id="s"
                    transform="translate(150,30)"
                    fill="#fc0"
                  >
                    <g id="c">
                      <path
                        id="t"
                        d="M 0,-20 V 0 H 10"
                        transform="rotate(18 0,-20)"
                      />
                      <use
                        xlink:href="#t"
                        transform="scale(-1,1)"
                      />
                    </g>
                    <use
                      xlink:href="#c"
                      transform="rotate(72)"
                    />
                    <use
                      xlink:href="#c"
                      transform="rotate(144)"
                    />
                    <use
                      xlink:href="#c"
                      transform="rotate(216)"
                    />
                    <use
                      xlink:href="#c"
                      transform="rotate(288)"
                    />
                  </g>
                  <use
                    xlink:href="#s"
                    transform="rotate(30 150,150) rotate(330 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(60 150,150) rotate(300 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(90 150,150) rotate(270 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(120 150,150) rotate(240 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(150 150,150) rotate(210 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(180 150,150) rotate(180 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(210 150,150) rotate(150 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(240 150,150) rotate(120 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(270 150,150) rotate(90 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(300 150,150) rotate(60 150,30)"
                  />
                  <use
                    xlink:href="#s"
                    transform="rotate(330 150,150) rotate(30 150,30)"
                  />
                </svg>
                <span class="country-abb">NL</span>
              </div>
              <div class="car-license__form-control"> 
                <input
                  id="input-kenteken" 
                  v-model="cardata.kenteken" 
                  type="text" 
                  class="car-license__input"
                  maxlength="8"
                  autocomplete="off"
                >  
                <span class="valid-message" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- <ValidationObserver class="box">
        <form
          @submit.prevent="
            updateregistration({
              step: 'licenseplate',
              registrationdata: {
                licenseplate: cardata.kenteken
                  .toUpperCase()
                  .replace(/[-/_]/g, ''),
              },
            })
          "
        >
          <BInputWithValidation
            v-model="cardata.kenteken"
            vid="any"
            name="Kenteken"
            rules="required"
            type="input"
            label="Kenteken"
            icon="car"
            buttontext="Verzenden"
            buttonicon="paper-plane"
            expanded-input
          />
           
         
        </form>
      </ValidationObserver> -->
      <template v-if="stepsDone.licenseplate">
        <h1 class="title has-text-light">
          Jouw autogegevens
        </h1>
        <div class="box">
          <table class="table is-narrow is-fullwidth">
            <tbody
              v-for="(value, name) in relevantcardata"
              :key="name"
            > 
              <tr v-if="name != 'Kenteken'">
                <th class="has-text-left has-text-light">
                  {{ name }}
                </th>
                <td class="has-text-right has-text-light">
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div
            v-for="(value, name) in relevantcardata"
            :key="name"
            class="has-text-light"
          >
            <div
              v-if="name != 'Kenteken'"
              class="columns"
            >
              <div
                class="column has-text-left"
              >
                {{ name }}:
              </div>
              <div class="column has-text-right">
                {{ value }}
              </div>
            </div>
          </div> -->
        </div>
        <a @click="toNextRoute($route.path)">
          <b-icon
            pack="fas"
            icon="chevron-down"
            size="is-large"
            type="is-light"
          />
          <span class="has-text-light">Dit is mijn auto</span>
          <!-- <p class="has-text-light">Ga naar de volgende stap</p>  -->
        </a>
        <!-- <b-checkbox
          v-model="checkcar"
          type="is-link"
          class="has-text-light"
          @change.native="
            toNextRoute(
              $route.path,
            )
          "
        >
          Dit is mijn auto
        </b-checkbox> -->
      </template>
    </div>
  </div>
</template>

<script>
// import { ValidationObserver } from "vee-validate";
// import BInputWithValidation from "../../components/inputs/BInputWithValidation";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Licenceplateregistration",
  components: {
    // ValidationObserver,
    // BInputWithValidation,
  },
  data() {
    return {
      licenseplate: "",
      checkcar: false,
    };
  },
  computed: {
    ...mapGetters(["cardata", "stepsDone", "relevantcardata"]),
  },
  methods: {
    ...mapActions(["updateregistration", "toNextRoute"]),

  },
};
</script>

<style lang="scss" scoped>
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');

//  @font-face {
//   font-family: kenteken;
//   src: url('https://frontend-developer.eu/fonts/kenteken.ttf');
// /*   this can give a CORS error because of the domain  */
// /* LeFly Fonts
//    https://www.dafont.com/kenteken.font    */
// }

// html {
//   box-sizing: border-box;

// }

// *, *:before, *:after {
//   box-sizing: inherit;
// }

// body {
//   font-family: 'Roboto Condensed';
// }

input:focus {
  outline: none;
}

// input:required {
//   border: 3px solid grey;
// }

// label{
//   display: block;
// }

.car-license {
  position: relative;
  max-width: 100%;
  background-color: #faca30;
  border-radius: 3px;
  border: 1px solid #faca30;
  z-index: 1;
  white-space: nowrap;
}

.car-license__country-code {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -1px;
  left: -1px;
  z-index: 2;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  height: 52px;
  width: 10%;
  background-color: #011c95;
  color: white;
  text-transform: uppercase;
}

.car-license__form-control,
.car-license__output{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #faca30;
  padding: 5px 10px 5px 30px;
  width: 100%;
  height: 50px;
  // font-family: kenteken, 'Roboto Condensed';
  font-size: 1.9rem;
  
}
// .car-license__form-control{
//   padding: 0 0 0 22px;

// }
.car-license__input{
  width: 100%;
  padding: 0;
  border: none;
  background-color: #faca30;
  text-align: center;
  text-transform: uppercase;
  font-family: inherit;
  font-size: inherit;
  margin:0;

}

.country-abb {
  font-size: 0.7rem;
}

// .html5-input {
//   display: inline-block;
//   font-size: 1.9rem;
//   max-width: 210px;
//   padding: 5px;
//   text-transform: uppercase;
// }

// .html5-input:valid {
//   border: 3px solid green;
// }

// .html5-input:focus:invalid {
//   border: red solid 3px;
// }

// .valid-message{
//   position: relative;
//   display: none;

// }
// .valid-message::before{
//   content: '';
// }

// .html5-input:valid + .valid-message,
// .valid + .valid-message{
//   display: inline-block;
// }

// .html5-input:valid + .valid-message::before,
// .valid + .valid-message::before{
//   /*  some css  */
//   position: absolute;
//   top: -25px;
//   content:'\2713';
//   color: green;
//   margin-left: 0.5rem;
//   font-size: 2rem;

// }

// .html5-input:valid + .valid-message::before {
//   position: static;
//   top: auto;
//   margin-left: auto;
// }
</style>